@import "./Components/styles";

.PayrollTableFiles:hover {
  cursor: pointer;

  span {
    color: @blue-700 !important;
  }

  svg path {
    fill: @blue-700 !important;
  }
}

.PayrollTableEditItem > div > svg:hover {
  cursor: pointer;

  path {
    fill: @blue-700 !important;
  }
}

.PayrollStatusTableColumn {
  width: 120px;
  border-radius: @pd-4;

  span {
    &:extend(.PaddingLeft12);
    &:extend(.PaddingTop6);
  }
}

.PayrollStatusTableComment {
  &:extend(.PaddingLeft6);
  &:extend(.PaddingTop6);
  &:extend(.PaddingRight6);

  border-top-right-radius: @pd-4;
  border-bottom-right-radius: @pd-4;
  cursor: default !important;
}

.PayrollStatus {
  &-pending {
    background-color: @orange-10;

    span {
      color: @orange-600 !important
    }

    .PayrollStatusTableComment {
      background-color: @orange-100;

      svg {
        path {
          fill: @orange-500;
        }
        circle {
          stroke: @orange-500;
        }
      }
    }

    .PayrollStatusTableComment:hover {

      svg {
        path {
          fill: @orange-600;
        }
        circle {
          stroke: @orange-600;
        }
      }
    }
  }

  &-success {
    background-color: @green-10;

    span {
      color: @green-600 !important
    }

    .PayrollStatusTableComment {
      background-color: @green-100;

      svg {
        path {
          fill: @green-500 !important
        }
        circle {
          stroke: @green-500 !important
        }
      }
    }
  }

  &-denied {
    background-color: @red-10;

    span {
      color: @red-600 !important
    }

    .PayrollStatusTableComment {
      background-color: @red-100;

      svg {
        path {
          fill: @red-500 !important
        }
        circle {
          stroke: @red-500 !important
        }
      }
    }

  }

  &-failed {
    background-color: @pink-10;

    span {
      color: @pink-600 !important
    }

    .PayrollStatusTableComment {
      background-color: @pink-100;

      svg {
        path {
          fill: @pink-500 !important
        }
        circle {
          stroke: @pink-500 !important
        }
      }
    }
  }

  &-delivered {
    background-color: @orange-10;

    span {
      color: @orange-600 !important
    }

    .PayrollStatusTableComment {
      background-color: @orange-100;

      svg {
        path {
          fill: @orange-500 !important
        }
        circle {
          stroke: @orange-500 !important
        }
      }
    }
  }

  &-new {
    background-color: @gray-100;

    span {
      color: @gray-700 !important
    }

    .PayrollStatusTableComment {
      background-color: @gray-200;

      svg {
        path {
          fill: @gray-600 !important
        }
        circle {
          stroke: @gray-600 !important
        }
      }
    }
  }
}

.PayrollTableEditItem {
  svg path {
    fill: @gray-600 !important;
  }
}

.PayrollTableRow > td {
  padding: 8px 12px !important;
}