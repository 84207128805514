@import "./Components/styles";

.SuperwinsGameListWrapper {
  &:extend(.White100Background);

  border: 1px solid @gray-200;
  border-radius: 8px;

  margin-top: @pd-24;
  margin-bottom: @pd-16;
  padding: @pd-16 @pd-24;
}

.SuperwinsGameListItem {
  width: 200px;
  padding: @pd-8 @pd-12;

  border: 1px solid @gray-200;
  border-radius: 8px;

  .BodySecondText {
    display: grid;
    place-items: center;
  }
}

@media screen and (max-width: 920px) {
  .SuperwinsGameListItem {
    max-width: 142px;

    .BodySecondText {
      max-width: 100px;
    }
  }

  .SuperwinsGameListWrapper {
    margin: @pd-16;
  }
}