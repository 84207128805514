.AccessModalContent {
  border-radius: 8px;
  border: 1px solid @gray-200;
  background: @white-100;

  padding: 40px 32px;
}

@media screen and (min-width: 921px){
  .AccessModalContentColumn {
    width: 33%;
  }
}

.AccessDashboardSelectItemWrapper {
  background: @white-100 !important;

  .ant-select-item-option-state {
    display: none;
  }
}

.AccessDashboardSelectItemWrapper .ant-select-item-option-content div {
  .CheckboxWrapper {
    margin-top: 0 !important;

    .CheckboxText {
      padding-left: 0 !important;
    }
  }
  div {
    margin-top: 3px;
  }
}

.AccessDashboardTag {
  padding: 6px 12px;

  border-radius: 8px;
  background: @white-800;
}

.AccessDashboardTag > div {
  height: 20px;
}

.AccessDashboardSelectWrapper .ant-select .ant-select-selector .ant-select-selection-overflow {
  gap: 8px
}

.AccessDashboardSelectWrapper {}

.CrudAccessMenuWrapper {
  border-radius: 8px;
  border: 1px solid @gray-400;

  align-content: flex-start;

  height: 100%;
}

.AccessMenuTagsWrapper {
  border-radius: 8px;
  background: @white-800;
  padding: 6px 12px;

  height: 32px;
}

.CustomTemplateNameRadio {
  display: flex !important;
  flex-direction: row;

  margin-top: -8px !important;
}

.MenuTagsItemLabel {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.AccessMenuMobileWrapper {
  height: 48px !important;

  .AccessMenuMobileSelectWrapper {
    height: 48px !important;

    .ant-select {
      height: 48px !important;

      .ant-select-selector {
        height: 48px !important;
      }
    }
  }
}

.AccessCrudMenuTags {
  height: 100%;
}

.AccessCrudPasswordSend {
  margin-top: -4px;
}

.AccessCrudPasswordSend .ant-form-item .ant-form-item-row .ant-form-item-control-input {
  min-height: 32px !important;
}

.AccessDashboardIcon {
  height: 20px;
  width: 20px;
}